import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagComponent } from './../tag.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'tag-id-search[tag=id-search]',
  templateUrl: './id-search.component.html',
  standalone: true,
  imports: [NgIf, TooltipModule, SlicePipe]
})
export class IdSearchComponent extends TagComponent {
  allowMultipleInstances = false;
  items = [];

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag() {
    if (this.fromTag) { return; }
    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value) && this.restoredFilter.value.join('') !== '') {
      this.value = this.restoredFilter.value.join('');
      this.items = this.value.split(',');
    } else {
      this.value = undefined;
      this.items = [];
    }
  }

  setTag(filter) {
    if (filter.type === this.type) {
      this.value = filter.value;
      this.items = this.value.split(',');
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type) {
      this.fromTag = true;
      if (filter.value && Array.isArray(filter.value) && filter.value.join('') !== '') {
        this.value = filter.value.join('');
        this.items = this.value.split(',');
      } else {
        this.value = undefined;
        this.items = [];
      }
    }
  }
}
