import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { FilterConfig } from '@parashift/shared/models';
import { TagLoaderComponent } from './tag-loader/tag-loader.component';
import { FilterToggleComponent } from './filter-toggle/filter-toggle.component';

@Component({
  selector: 'filter-tags',
  templateUrl: './filter-tags.component.html',
  standalone: true,
  imports: [FilterToggleComponent, NgFor, TagLoaderComponent]
})
export class FilterTagsComponent {
  @Input() filterConfig: FilterConfig;
  @Input() openOnLoad?: boolean;
}
