import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { TagComponent } from 'shared/components/filter-tags/tags/tag.component';

@Component({
  selector: 'tag-tags-any[tag=tags-any]',
  templateUrl: './tags-any.component.html',
  standalone: true,
  imports: [NgIf, SlicePipe]
})
export class TagsAnyComponent extends TagComponent {
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag(): void {
    this.initResetForeignFilterListener();

    if (this.fromTag) { return; }
    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value)) {
      this.setLabel(this.restoredFilter.value);
    } else {
      this.value = undefined;
    }
  }

  setTag(filter) {
    if (filter.type === this.type) {
      if (filter.value) {
        this.setLabel(filter.value);
      } else {
        this.value = undefined;
      }
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type) {
      this.fromTag = true;
      if (filter.value && Array.isArray(filter.value)) {
        this.setLabel(filter.value);
      } else {
        this.value = undefined;
      }
    }
  }

  setLabel(value) {
    const label = [];
    value.forEach(val => label.push(val[this.config.tagDisplay || this.config.sourceField || 'key']));
    this.value = label.join(', ');
  }

  private initResetForeignFilterListener() {
    this.filterService.resetForeignFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => {
      if (filter.type === this.type && filter.value === 'false') {
        this.resetTagWithoutEvents();
      }
    });
  }

  private resetTagWithoutEvents() {
    this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: '' });
    this.value = undefined;
  }
}
