import { Input, OnInit, Directive, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { FilterService, RestoreFilterParams, Filter } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TagComponent implements OnInit {
  @Input() config: any;
  @Input() identifier: string;
  @Input() type: string;

  protected restoredFilter: any = { field: '', value: '' };

  abstract allowMultipleInstances: boolean;

  restoreFilterParams: RestoreFilterParams;
  value: any;
  fromTag = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {}

  abstract initTag(): void;
  abstract setTagFromTab(filter: Filter): void;

  ngOnInit() {
    this.setParams();
    this.restoreFilter();
    this.initFilterListener();
    this.initFilterTabListener();
    this.initTag();
  }

  setParams() {
    this.restoreFilterParams = {
      allowMultipleInstances: this.allowMultipleInstances,
      identifier: this.identifier,
      filterType: this.type,
      field: this.config.field || this.config.filter || undefined
    };
  }

  restoreFilter() {
    this.restoredFilter = this.filterService.restoreFilter(this.restoreFilterParams);
  }

  initFilterListener() {
    this.filterService.filterChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => {
      this.setTag(filter);
    });
  }

  initFilterTabListener() {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const filter = this.filterTabsService.getUserDefinedFilter(this.type, this.config.field, false);
      this.setTagFromTab(filter);
    });
  }

  setTag(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.value = filter.value;
      this.fromTag = false;
    }
  }

  removeFilter() {
    this.filterService.setFilter({ type: this.type, field: this.config.field, value: '' });
    this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: '' });
    this.value = undefined;
  }
}
