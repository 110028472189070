import { Injectable } from '@angular/core';
import { CheckboxComponent } from './tags/checkbox/checkbox.component';
import { DateRangeComponent } from './tags/date-range/date-range.component';
import { DocumentTypeComponent } from './tags/document-type/document-type.component';
import { GlobalRolesComponent } from './tags/global-roles/global-roles.component';
import { IdSearchComponent } from './tags/id-search/id-search.component';
import { MultiSelectboxComponent } from './tags/multi-selectbox/multi-selectbox.component';
import { MultiselectTypeaheadComponent } from './tags/multiselect-typeahead/multiselect-typeahead.component';
import { NumberRangeComponent } from 'shared/components/filter-tags/tags/number-range/number-range.component';
import { SearchComponent } from './tags/search/search.component';
import { SelectboxComponent } from './tags/selectbox/selectbox.component';
import { TagsAllComponent } from './tags/tags-all/tags-all.component';
import { TagsAnyComponent } from './tags/tags-any/tags-any.component';
import { TenantUsersComponent } from './tags/tenant-users/tenant-users.component';

@Injectable()
export class TagMappings {
  checkbox = CheckboxComponent;
  'date-range' = DateRangeComponent;
  'document-type' = DocumentTypeComponent;
  'global-roles' = GlobalRolesComponent;
  'id-search' = IdSearchComponent;
  'multiselectbox' = MultiSelectboxComponent;
  'multiselect-typeahead' = MultiselectTypeaheadComponent;
  'number-range' = NumberRangeComponent;
  search = SearchComponent;
  selectbox = SelectboxComponent;
  'tags-all' = TagsAllComponent;
  'tags-any' = TagsAnyComponent;
  'tenant-users' = TenantUsersComponent;
}
