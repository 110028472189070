import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TagComponent } from './../tag.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { format } from 'date-fns-tz';

@Component({
  selector: 'tag-date-range[tag=date-range]',
  templateUrl: './date-range.component.html',
  standalone: true,
  imports: [NgIf]
})
export class DateRangeComponent extends TagComponent {
  allowMultipleInstances = true;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag() {
    if (this.fromTag) { return; }
    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value) && this.restoredFilter.value.length > 0) {
      this.value = this.formatDates(this.restoredFilter.value[0]);
    } else {
      this.value = undefined;
    }
  }

  setTag(filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      if (
        filter.value &&
        filter.value.length > 1 &&
        Object.prototype.toString.call(filter.value[0]) === '[object Date]' &&
        Object.prototype.toString.call(filter.value[1]) === '[object Date]'
      ) {
        if (format(filter.value[0], 'dd-MM-yyyy') === format(filter.value[1], 'dd-MM-yyyy')) {
          this.value = format(filter.value[0], 'dd-MM-yyyy');
        } else {
          this.value =format(filter.value[0], 'dd-MM-yyyy') + ' - ' + format(filter.value[1], 'dd-MM-yyyy');
        }
      } else {
        this.value = '';
      }
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      this.fromTag = true;
      if (filter.value && Array.isArray(filter.value) && filter.value.length > 0) {
        this.value = this.formatDates(filter.value[0]);
      } else {
        this.value = undefined;
      }
    }
  }

  formatDates(range) {
    const dates = range.split('..');

    if (dates[0] === dates[1]) {
      return dates[0];
    } else {
      return dates[0] + ' - ' + dates[1];
    }
  }
}
