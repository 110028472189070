import { Component, OnDestroy, OnInit, ElementRef, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ColumnsService } from './../../columns/columns.service';
import { FilterService } from '@parashift/shared/services';
import { SessionService } from '@parashift/shared/services';

@Component({
  selector: 'filter-toggle',
  templateUrl: './filter-toggle.component.html',
  standalone: true,
  imports: [FaIconComponent, NgIf]
})
export class FilterToggleComponent implements OnDestroy, OnInit {
  @Input() openOnLoad: boolean;

  toggleLeft = false;
  toggleLeftColumnSubscription: Subscription;
  filterEventSubscription: Subscription;
  currentIdentifierLeft = 'filter';
  showInfo = false;

  constructor(
    public columnsService: ColumnsService,
    private filterService: FilterService,
    private sessionService: SessionService,
    private elementRef: ElementRef
  ) {
    this.toggleLeftColumnSubscription = this.columnsService.toggleLeftColumn$.subscribe(toggle => {
      this.toggleLeft = toggle;
    });

    if (this.sessionService && this.sessionService.layout && this.sessionService.layout.column_left && this.sessionService.layout.column_left.filter) {
      this.toggleLeft = true;
    }
  }

  ngOnInit() {
    if (this.openOnLoad) {
      setTimeout(() => {
        this.toggleFilterColumn();
      });
    }

    this.initFilterEventListener();
  }

  toggleFilterColumn() {
    this.columnsService.toggleLeftColumn(!this.toggleLeft, 'filter', false);
    if (!this.toggleLeft) {
      this.columnsService.toggleLeftModel = undefined;
    }
  }

  initFilterEventListener() {
    this.filterEventSubscription = this.filterService.filterEvent$.subscribe(() => {
      setTimeout(() => {
        let numberOfShownTags = 0;

        if (this.elementRef.nativeElement && this.elementRef.nativeElement.parentElement && this.elementRef.nativeElement.parentElement.children) {
          if (this.elementRef.nativeElement.parentElement.children.length > 1) {
            for (let i = 1; i < this.elementRef.nativeElement.parentElement.children.length; i++) {
              const node = this.elementRef.nativeElement.parentElement.children[i];
              if (node.lastChild && node.lastChild.firstElementChild) {
                numberOfShownTags += 1;
              }
            }
          }
        }

        this.showInfo = numberOfShownTags > 0 ? false : true;
      }, 0);
    });
  }

  ngOnDestroy() {
    this.toggleLeftColumnSubscription.unsubscribe();
    this.filterEventSubscription.unsubscribe();
  }
}
