<a class="tag"
   (click)="removeFilter()"
   *ngIf="value"
>
<span *ngIf="items.length > 1; else fullValue" tooltip="{{ value | slice: 0 : 150 }}{{ value.length > 150 ? '...' : '' }}" container="body" boundariesElement="viewport">
  <span class="prefix">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@common.id_search">ID-Search</ng-template>
  </span>
  {{ items[0] }} (+{{ items.length - 1 }}) <span aria-hidden="true" class="times">&times;</span></span>
  <ng-template #fullValue>
    <span class="prefix">
      <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@common.id_search">ID-Search</ng-template>
    </span>
    {{ value }} <span aria-hidden="true" class="times">&times;</span>
  </ng-template>
</a>
