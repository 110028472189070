import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TagComponent } from './../tag.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { ParashiftRoles } from '@parashift/shared/constants';

@Component({
  selector: 'tag-global-roles[tag=global-roles]',
  templateUrl: './global-roles.component.html',
  standalone: true,
  imports: [NgIf]
})
export class GlobalRolesComponent extends TagComponent {
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag() {
    if (this.fromTag) { return; }
    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value) && this.restoredFilter.value[0]) {
      this.value = this.getLabel(this.restoredFilter.value[0]);
    } else {
      this.value = undefined;
    }
  }

  setTag(filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      if (filter.value) {
        this.value = this.getLabel(filter.value);
      } else {
        this.value = undefined;
      }
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      this.fromTag = true;
      if (filter.value && Array.isArray(filter.value) && filter.value[0]) {
        this.value = this.getLabel(filter.value[0]);
      } else {
        this.value = undefined;
      }
    }
  }

  getLabel(value) {
    return ParashiftRoles[value];
  }
}
