import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagComponent } from './../tag.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'tag-search[tag=search]',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [NgIf, TooltipModule, SlicePipe]
})
export class SearchComponent extends TagComponent {
  allowMultipleInstances = true;
  items = [];

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag() {
    if (this.fromTag) { return; }
    if (this.restoredFilter.value) {
      this.value = this.restoredFilter.value;
      if (this.config.numbersOnly) {
        this.items = this.value.split(',');
      } else {
        this.items = [];
      }
    } else {
      this.value = undefined;
      this.items = [];
    }
  }

  setTag(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.value = filter.value;
      this.fromTag = false;
      if (this.config.numbersOnly) {
        this.items = this.value.split(',');
      } else {
        this.items = [];
      }
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      this.value = filter.value;
      this.fromTag = true;
      if (this.config.numbersOnly) {
        this.items = this.value.split(',');
      } else {
        this.items = [];
      }
    } else {
      this.items = [];
    }
  }
}
