import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TagMappings } from './../tag.mappings';
import { TagComponent } from './../tags/tag.component';

const filterComponentsWithoutTags = ['sorting', 'headline', 'reset', 'group-by'];

@Component({
  selector: 'tag-loader',
  templateUrl: './tag-loader.component.html',
  standalone: true,
  providers: [TagMappings]
})
export class TagLoaderComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @Input() type: string;
  @Input() config: any;
  @Input() identifier: string;

  private componentRef: ComponentRef<TagComponent>;

  constructor(private tagMappings: TagMappings) {}

  ngOnInit() {
    if (this.type) {
      if (filterComponentsWithoutTags.includes(this.type)) {
        return;
      }

      const componentType = this.getComponentType(this.type);
      this.componentRef = this.container.createComponent(componentType);
      this.componentRef.instance.config = this.config;
      this.componentRef.instance.identifier = this.identifier;
      this.componentRef.instance.type = this.type;
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private getComponentType(typeName: string) {
    const type = this.tagMappings[typeName];
    return type;
  }
}
