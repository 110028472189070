import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagComponent } from 'shared/components/filter-tags/tags/tag.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'tag-multi-selectbox[tag=multi-selectbox]',
  templateUrl: './multi-selectbox.component.html',
  standalone: true,
  imports: [NgIf, TooltipModule, SlicePipe]
})
export class MultiSelectboxComponent extends TagComponent {
  allowMultipleInstances = true;
  items = [];

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag(): void {
    if (this.fromTag) { return; }
    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value) && this.restoredFilter.value[0]) {
      this.setLabel(this.restoredFilter.value[0]);
    } else {
      this.value = undefined;
      this.items = [];
    }
  }

  setTag(filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      if (filter.value) {
        this.setLabel(filter.value);
      } else {
        this.value = undefined;
        this.items = [];
      }
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      this.fromTag = true;
      if (filter.value && Array.isArray(filter.value) && filter.value[0]) {
        this.setLabel(filter.value[0]);
      } else {
        this.value = undefined;
        this.items = [];
      }
    }
  }

  setLabel(value) {
    const values = value.split(',');

    if (this.config.options instanceof Observable) {
      this.config.options.subscribe(
        (list) => {
          const options = list.filter(obj => values.some(val => val === obj.value)).map(x => x.label);
          this.items = options.length > 0 ? this.removeDuplicates(options) : [];
          this.value = this.items.join(', ');
        }
      );
    } else {
      const options = this.config.options.filter(obj => values.some(val => val === obj.value)).map(x => x.label);
      this.items = options.length > 0 ? this.removeDuplicates(options) : [];
      this.value = this.items.join(', ');
    }
  }

  private removeDuplicates(options: string[]): string[] {
    const uniqueOptions = [];
    options.forEach((option) => {
      if (!uniqueOptions.includes(option)) {
          uniqueOptions.push(option);
      }
    });

    return uniqueOptions;
  }
}
