import { Component, DestroyRef, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { TagComponent } from './../tag.component';
import { ActivatedRoute } from '@angular/router';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'tag-checkbox[tag=checkbox]',
  templateUrl: './checkbox.component.html',
  standalone: true,
  imports: [NgIf]
})
export class CheckboxComponent extends TagComponent {
  allowMultipleInstances = true;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, destroyRef);
  }

  initTag() {
    if (this.fromTag) { return; }
    const valueIndex = this.findFilterValue(this.restoredFilter.value, this.config.value);

    if ((this.config.checked && this.restoredFilter.value && this.restoredFilter.value.length === 0) || valueIndex > -1) {
      this.value = this.config.value;
    } else {
      this.value = undefined;
    }
  }

  setTag(filter) {
    if (filter.type === this.type && filter.field === this.config.filter && filter.value === this.config.value) {
      if (filter.checked) {
        this.value = filter.value;
      } else {
        this.value = undefined;
      }
      this.fromTag = false;
    }
  }

  setTagFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.filter) {
      this.fromTag = true;
      const valueIndex = this.findFilterValue(filter.value, this.config.value);

      if ((this.config.checked && filter.value && filter.value.length === 0) || valueIndex > -1) {
        this.value = this.config.value;
      } else {
        this.value = undefined;
      }
    }
  }

  removeFilter() {
    this.filterService.setFilter({ type: this.type, field: this.config.filter, value: this.config.value, checked: false });
    this.value = undefined;
  }

  findFilterValue(filterValues, search) {
    return filterValues.indexOf(search);
  }
}
